import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
} from "@mui/material";
import React from "react";
import styles from "./CreateVideoModal.module.css";
import {
    TypographyBodyLarge, TypographyCTA1
} from "../../../../components/StyledComponets/Typography";
import { PrimaryButton, SecondaryButton } from "../../../../components/StyledComponets/Button";
import ClearIcon from '@mui/icons-material/Clear';
import { CREATE_VIDEO_PROJECT_STEP } from "utils/enums";
import ProjectStepper from "../ProjectStepper";
import { SceneAsset } from "utils/types";


type SelectCameraAndAnimationStepProps = {
    onClose: React.MouseEventHandler<HTMLDivElement>;
    onBack: React.MouseEventHandler<HTMLButtonElement>;
    onGotoEditor: React.MouseEventHandler<HTMLButtonElement>;
    selectScene: SceneAsset[] | [];
    projectName: string

};

const SelectCameraAndAnimationStep = ({ onClose, projectName, onBack, selectScene, onGotoEditor }: SelectCameraAndAnimationStepProps) => {

    return (
        <>
            <DialogTitle className={styles.titleDiv}>
                <Grid
                    display={"flex"}
                    alignItems={"flex-start"}
                    justifyContent={"space-between"}
                    gap={3}
                    width={"100%"}
                >
                    <TypographyBodyLarge
                        sx={{
                            color: "var(--color-palette-white, #FFF)",
                            alignSelf: "center",
                        }}
                    >
                        {projectName}
                    </TypographyBodyLarge>

                    <div onClick={onClose} className={styles.iconDiv} >
                        <ClearIcon sx={{ color: 'white' }} />
                    </div>
                </Grid>
                <ProjectStepper currentStep={CREATE_VIDEO_PROJECT_STEP.CHOOSE_CAMERA_ANIMATION} />
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    padding={"1rem"}

                    sx={{
                        borderRadius: '1rem',
                        border: '1px solid #394455'
                    }}
                >
                    <Grid
                        display={"flex"}
                        flexDirection={"column"}
                        xs={8}
                        paddingRight={"0.5rem"}
                    >

                    </Grid>
                    <Grid
                        display={"flex"}
                        flexDirection={"column"}
                        xs={4}
                        paddingLeft={"0.5rem"}
                        gap={"0.5rem"}
                    >

                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <div className={styles.lastDiv}>
                    <SecondaryButton onClick={onBack} sx={{ p: "16px 24px" }}>
                        <TypographyCTA1>Back</TypographyCTA1>
                    </SecondaryButton>
                    <PrimaryButton onClick={onGotoEditor} sx={{ p: "16px 24px" }}>
                        <TypographyCTA1>Next</TypographyCTA1>
                    </PrimaryButton>
                </div>
            </DialogActions>
        </>
    );
};

export default React.memo(SelectCameraAndAnimationStep);
