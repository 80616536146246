import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { deleteApi, getApi, postApi, putApi } from '../utils/fetchUtils';
import { CallbackType, Output, showSnackbarType } from '../utils/types';
import { PROJECT_TYPE, SEVERITY } from '../utils/enums';
import { AppThunk } from '../store';

const initialState = {
  allOutputs: null as { [id: string]: Output } | null,
  billboardOutputs: null as { [id: string]: Output[] } | null,
  unrealOutputs: null as { [id: string]: Output[] } | null,
  diffusionOutputs: null as { [id: string]: Output[] } | null,
  videoOutputs: null as { [id: string]: Output[] } | null,
  imageOutputs: null as { [id: string]: Output[] } | null,
  otherOutputs: null as Output[] | null
};

const outputSlice = createSlice({
  name: "output",
  initialState,
  reducers: {
    updateAllOutputs: (state, action: PayloadAction<Output[]>) => {
      state.allOutputs = {};
      action.payload.forEach(output => {
        if (!state.allOutputs) { state.allOutputs = {}; }
        state.allOutputs[output.id] = output;
      });
    },

    updateBillboardOutputs: (state, action: PayloadAction<Output[]>) => {
      state.billboardOutputs = {};
      action.payload.forEach(output => {
        if (!output.projectId) { return; }
        if (!state.billboardOutputs) { state.billboardOutputs = {}; }

        if (!state.billboardOutputs[output.projectId]) state.billboardOutputs[output.projectId] = [output];
        else state.billboardOutputs[output.projectId].push(output);
      });
    },

    updateUnrealOutputs: (state, action: PayloadAction<Output[]>) => {
      state.unrealOutputs = {};
      action.payload.forEach(output => {
        if (!output.projectId) { return; }
        if (!state.unrealOutputs) { state.unrealOutputs = {}; }

        if (!state.unrealOutputs[output.projectId]) state.unrealOutputs[output.projectId] = [output];
        else state.unrealOutputs[output.projectId].push(output);
      });
    },

    updateDiffusionOutputs: (state, action: PayloadAction<Output[]>) => {
      state.diffusionOutputs = {};
      action.payload.forEach(output => {
        if (!output.projectId) { return; }
        if (!state.diffusionOutputs) { state.diffusionOutputs = {}; }

        if (!state.diffusionOutputs[output.projectId]) state.diffusionOutputs[output.projectId] = [output];
        else state.diffusionOutputs[output.projectId].push(output);
      });
    },

    updateVideoOutputs: (state, action: PayloadAction<Output[]>) => {
      state.videoOutputs = {};
      action.payload.forEach(output => {
        if (!output.projectId) { return; }
        if (!state.videoOutputs) { state.videoOutputs = {}; }

        if (!state.videoOutputs[output.projectId]) state.videoOutputs[output.projectId] = [output];
        else state.videoOutputs[output.projectId].push(output);
      });
    },

    updateImageOutputs: (state, action: PayloadAction<Output[]>) => {
      state.imageOutputs = {};
      action.payload.forEach(output => {
        if (!output.projectId) { return; }
        if (!state.imageOutputs) { state.imageOutputs = {}; }

        if (!state.imageOutputs[output.projectId]) state.imageOutputs[output.projectId] = [output];
        else state.imageOutputs[output.projectId].push(output);
      });
    },

    updateOtherOutputs: (state, action: PayloadAction<Output[]>) => {
      state.otherOutputs = [];
      action.payload.forEach(output => {
        if (!state.otherOutputs) { state.otherOutputs = []; }

        state.otherOutputs.push(output);
      });
    },

    updateOutput: (state, action: PayloadAction<Output>) => {
      const output = action.payload;

      if (!state.allOutputs) { state.allOutputs = {}; }
      if (!state.billboardOutputs) { state.billboardOutputs = {}; }
      if (!state.unrealOutputs) { state.unrealOutputs = {}; }
      if (!state.diffusionOutputs) { state.diffusionOutputs = {}; }
      if (!state.videoOutputs) { state.videoOutputs = {}; }
      if (!state.imageOutputs) { state.imageOutputs = {}; }
      if (!state.otherOutputs) { state.otherOutputs = []; }

      state.allOutputs[output.id] = output;
      if (output.projectId) {

        switch (output.type) {
          case PROJECT_TYPE.BILLBOARD:
            if (state.billboardOutputs[output.projectId]) state.billboardOutputs[output.projectId].push(output);
            else state.billboardOutputs[output.projectId] = [output]
            break;

          case PROJECT_TYPE.UNREAL:
          case PROJECT_TYPE.UNREAL_SCENE:
            if (state.unrealOutputs[output.projectId]) state.unrealOutputs[output.projectId].push(output);
            else state.unrealOutputs[output.projectId] = [output]
            break;

          case PROJECT_TYPE.STABLE_DIFFUSION:
            if (state.diffusionOutputs[output.projectId]) state.diffusionOutputs[output.projectId].push(output);
            else state.diffusionOutputs[output.projectId] = [output]
            break;

          case PROJECT_TYPE.VIDEO:
            if (state.videoOutputs[output.projectId]) state.videoOutputs[output.projectId].push(output);
            else state.videoOutputs[output.projectId] = [output]
            break;

          case PROJECT_TYPE.IMAGE:
              if (state.imageOutputs[output.projectId]) state.imageOutputs[output.projectId].push(output);
              else state.imageOutputs[output.projectId] = [output]
              break;
  
          default:
            break;
        }
      } else {
        state.otherOutputs.push(output);
      }
    },
  },
});

export default outputSlice.reducer;

export const getAllOutputs = (callback?: CallbackType): AppThunk =>
  async dispatch => {
    const allOutputs: Output[] = await getApi("renders/");
    dispatch(outputSlice.actions.updateAllOutputs(allOutputs));
    dispatch(outputSlice.actions.updateVideoOutputs(allOutputs.filter(output => output.type === PROJECT_TYPE.VIDEO)));
    dispatch(outputSlice.actions.updateImageOutputs(allOutputs.filter(output => [PROJECT_TYPE.EMPTY, PROJECT_TYPE.IMAGE, PROJECT_TYPE.IMAGE_SET, PROJECT_TYPE.FACEBOOK_AD, PROJECT_TYPE.IMAGE_GENERATION, PROJECT_TYPE.IMAGE_EDITOR].includes(output.type))));
    callback?.();
  }

export const getOutputById = (id: string): AppThunk =>
  async dispatch => {
    const output: Output = await getApi(`output/${id}`);
    dispatch(outputSlice.actions.updateOutput(output));
  }

export const updateOutputById = (id: string, payload: any): AppThunk =>
  async dispatch => {
    const output: Output = await postApi(`output/${id}`, payload);
    dispatch(outputSlice.actions.updateOutput(output));
  }

export const deleteOutputs = (ids: string[], showSnackbar?: showSnackbarType, callback?: CallbackType): AppThunk =>
  async dispatch => {
    for (const id of ids) {
      await deleteApi(`output/${id}`);
    }
    showSnackbar?.("Outputs deleted.", SEVERITY.SUCCESS);
    if (showSnackbar) {
      dispatch(getAllOutputs(callback));
    } else {
      callback?.();
    }
  }

export const uploadOutput = (payload: { file: Blob, projectId: string, mimeType: string, finalMimeType?: string, key?: string }): AppThunk =>
  async dispatch => {
    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("mime_type", payload.mimeType);
    formData.append("project_id", payload.projectId);
    payload.finalMimeType && formData.append("final_mime_type", payload.finalMimeType);
    payload.key && formData.append("key", payload.key);

    await putApi("output", formData);
    dispatch(getAllOutputs());
  }

export const uploadBulkOutputs = (payloads: any[], callback?: CallbackType): AppThunk =>
  async dispatch => {
    for (const payload of payloads) {
      const formData = new FormData();
      formData.append("file", payload.file);
      formData.append("mime_type", payload.mimeType);
      formData.append("project_id", payload.projectId);
      payload.finalMimeType && formData.append("final_mime_type", payload.finalMimeType);
      payload.key && formData.append("key", payload.key);
      payload.metadata && formData.append("metadata", JSON.stringify(payload.metadata));

      await putApi("output", formData);
    }
    dispatch(getAllOutputs(callback));
  }
