import { lazy } from "react";
import { Outlet } from "react-router-dom";
import { ROUTE_PATH_MAP } from "../utils/enums";
import Navbar from "../components/Navbar";
import { Grid } from "@mui/material";
import Header from "components/Header";

const Home = lazy(() => import("../containers/home"));
const Create = lazy(() => import("../containers/create"));
const Folder = lazy(() => import("../containers/folder"));
const Render = lazy(() => import("../containers/render"));
const Assets = lazy(() => import("../containers/assets"));
const Prompts = lazy(() => import("../containers/prompts"))
const EditorWidget = lazy(() => import("../containers/editor_widget"))
const VideoEditor = lazy(() => import("../containers/video_editor"));
// const VideoEditorNavbar = lazy(() =>
//   import("../containers/video_editor/EditorNavbar.tsx")
// );
const VideoEditorNavbar = lazy(() =>
  import("../containers/video_editor/EditorNavbar2.tsx")
);
const VideoEditorPanel = lazy(() =>
  import("../containers/video_editor/EditorRightPanel")
);
const VideoEditorHeader = lazy(() => import("../components/Header"));
const InFolder = lazy(() => import("../containers/folder/InFolder"));

export const NewComponentWrapper = () => (
  <>
    <Header transparentBg />
    <Grid
      display={"flex"}
      flexDirection={"row"}
      sx={{ background: "var(--color-palette-gray-900, #1A202B)" }}
      minHeight={"calc(100vh - 72px)"}
    >
      <Navbar />
      <Grid
        margin={0}
        display={"flex"}
        flexDirection={"column"}
        minHeight={"calc(100vh - 72px)"}
        padding={"32px 28px 32px 24px"}
        width={"100%"}
        sx={{ background: "var(--color-palette-gray-900, #1A202B)" }}
        gap={2}
      >
        <Outlet />
      </Grid>
    </Grid>
  </>
);

export const ProjectComponentWrapper = ({ showSteps }) => (
  <>
    {/* <Header showSteps={showSteps} transparentBg /> */}

    <Grid
      display={"flex"}
      flexDirection={"row"}
      minHeight={"calc(100vh)"}
      sx={{
        background: "var(--color-palette-gray-900, #1A202B)",
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/dotted_background.png)`,
        backgroundRepeat: "repeat",
      }}
    >
      <Outlet />
    </Grid>
  </>
);
export const routes = [
  /* new routes with both header and navbar */
  {
    path: "/",
    element: <NewComponentWrapper />,
    children: [
      {
        path: ROUTE_PATH_MAP.HOME,
        element: <Home />,
      },
      {
        path: ROUTE_PATH_MAP.CREATE,
        element: <Create />,
      },
      {
        path: ROUTE_PATH_MAP.FOLDERS,
        element: <Folder />,
      },
      {
        path: ROUTE_PATH_MAP.RENDERS,
        element: <Render />,
      },
      {
        path: ROUTE_PATH_MAP.ASSETS,
        element: <Assets />,
      },
      {
        path: `${ROUTE_PATH_MAP.FOLDERS}/:folderId`,
        element: <InFolder />,
      },
      {
        path: ROUTE_PATH_MAP.PROMPTS,
        element: <Prompts />,
      },
    ],
  },
  
  /* new routes with header and steps */
  {
    path: "/",
    element: <ProjectComponentWrapper showSteps />,
    children: [
      {
        path: `${ROUTE_PATH_MAP.VIDEO_EDITOR}/:projectId`,
        element: <VideoEditor />,
      },
      {
        path: `${ROUTE_PATH_MAP.VIDEO_EDITOR}/:projectId/navbar`,
        element: <VideoEditorNavbar />,
      },
      {
        path: `${ROUTE_PATH_MAP.VIDEO_EDITOR}/:projectId/header`,
        element: <VideoEditorHeader showSteps transparentBg />,
      },
      {
        path: `${ROUTE_PATH_MAP.VIDEO_EDITOR}/:projectId/panel`,
        element: <VideoEditorPanel />,
      },
      {
        path: ROUTE_PATH_MAP.EDITOR_WIDGET,
        element: <EditorWidget />,
      },
    ],
  },
];

export default routes;
