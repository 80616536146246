import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { deleteApi, getApi, postApi } from "../utils/fetchUtils";
import {
  CallbackModelType,
  CallbackType,
  Folder,
  showSnackbarType,
} from "../utils/types";
import { SEVERITY } from "../utils/enums";
import { AppThunk } from "../store";
import { getAllProjects } from "./project";

const initialState = {
  allFolders: null as { [id: string]: Folder } | null,
  brands: [],
  users: [],
  tags: [],
  folders: [] as any,
};

const folderSlice = createSlice({
  name: "folder",
  initialState,
  reducers: {
    updateAllFolders: (state, action: PayloadAction<Folder[]>) => {
      state.allFolders = {};
      state.folders = action.payload;
      action.payload.forEach((folder) => {
        if (!state.allFolders) {
          state.allFolders = {};
        }
        state.allFolders[folder.id] = folder;
      });
    },
    updateBrands: (state, action: PayloadAction<any>) => {
      state.brands = action.payload;
    },

    updateUsers: (state, action: PayloadAction<any>) => {
      state.users = action.payload;
    },

    updateTags: (state, action: PayloadAction<any>) => {
      state.tags = action.payload;
    },
  },
});

export default folderSlice.reducer;

export const getAllFolders =
  (callback?: CallbackType): AppThunk =>
  async (dispatch) => {
    const allFolders: Folder[] = await getApi("folders/");
    dispatch(folderSlice.actions.updateAllFolders(allFolders));
    callback?.();
  };

export const createFolder =
  (
    payload: any,
    showSnackbar?: showSnackbarType,
    callback?: CallbackModelType<Folder>
  ): AppThunk =>
  async (dispatch) => {
    try {
      const folder: Folder = await postApi("folders/", payload);
      showSnackbar?.("Folder created.", SEVERITY.SUCCESS);
      if (showSnackbar) {
        dispatch(getAllFolders(() => callback?.(folder)));
      } else {
        callback?.(folder);
      }
    } catch {
      showSnackbar?.("Something went wrong.", SEVERITY.ERROR);
    }
  };

export const updateFolderById =
  (id: string, name: string, showSnackbar?: showSnackbarType): AppThunk =>
  async (dispatch) => {
    await postApi(`folder/${id}`, { name: name.trim() });
    showSnackbar?.("Folder updated.", SEVERITY.SUCCESS);
    if (showSnackbar) {
      dispatch(getAllFolders());
    }
  };

export const deleteFolderById =
  (
    id: string,
    showSnackbar?: showSnackbarType,
    callback?: CallbackType
  ): AppThunk =>
  async (dispatch) => {
    await deleteApi(`folders/${id}/`);
    showSnackbar?.("Folder deleted.", SEVERITY.SUCCESS);
    if (showSnackbar) {
      dispatch(getAllFolders(callback));
      dispatch(getAllProjects());
    } else {
      callback?.();
    }
  };

export const getBrands =
  (callback?: CallbackType): AppThunk =>
  async (dispatch) => {
    const brands = await getApi("brands/");
    dispatch(folderSlice.actions.updateBrands(brands));

    callback?.();
  };
export const getTags =
  (callback?: CallbackType): AppThunk =>
  async (dispatch) => {
    const tags = await getApi("tags/");
    dispatch(folderSlice.actions.updateTags(tags));

    callback?.();
  };
export const getUsers =
  (callback?: CallbackType): AppThunk =>
  async (dispatch) => {
    const users = await getApi("users/");
    dispatch(folderSlice.actions.updateUsers(users));
    callback?.();
  };
