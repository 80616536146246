import axios, { AxiosError, AxiosResponse } from "axios";
import { TOKEN_SOURCE } from "./enums";

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const API_KEY = process.env.REACT_APP_BACKEND_API_KEY;

function successHandler(response: AxiosResponse) {
  if (response?.data) {
    return response.data;
  } else {
    console.error("API error", response);
  }
}

function errorHandler(err: AxiosError | any) {
  if (err.response?.status === 401) {
    const tokenSource = window.localStorage.getItem("token_source");
    if (tokenSource && tokenSource === TOKEN_SOURCE.SESSION) {
      window.location.assign("/auth/expired");
    } else {
      window.location.assign("/auth/logout");
    }
  } else {
    console.error("API error", err.response?.data);
    throw (
      err.response?.data?.error ?? err.response?.data ?? err.response ?? err
    );
  }
}

function getHeaders(payload?: any, isPublic: boolean = false) {
  const headers: any = payload
    ? payload instanceof FormData
      ? { "Content-Type": "multipart/form-data" }
      : { "Content-Type": "application/json" }
    : {};

  const token = window.localStorage.getItem("token");
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  const brandId = window.localStorage.getItem("brand_id");
  if (brandId) {
    headers["x-brand-id"] = brandId;
  }
  if (isPublic) {
    headers["x-api-key"] = API_KEY;
  }

  return headers;
}

export const createBackendUrl = (url: string) => `${BASE_URL}api/${url}`;

export function getApi(url: string, isPublic?: boolean) {
  return axios
    .get(createBackendUrl(url), { headers: getHeaders(null, isPublic) })
    .then(successHandler)
    .catch(errorHandler);
}

export function getApiWithoutCatch(url: string) {
  return axios
    .get(createBackendUrl(url), { headers: getHeaders() })
    .then(successHandler);
}

export function postApi(
  url: string,
  payload: any = {},
  isPublic: boolean = false
) {
  return axios
    .post(createBackendUrl(url), payload, {
      headers: getHeaders(payload, isPublic),
    })
    .then(successHandler)
    .catch(errorHandler);
}

export function postFormApi(
  url: string,
  payload: any = {},
  isPublic: boolean = false
) {
  const formData = new FormData();
  for (const key in payload) {
    formData.append(key, payload[key]);
  }

  return axios
    .post(createBackendUrl(url), formData, {
      headers: getHeaders(formData, isPublic),
    })
    .then(successHandler)
    .catch(errorHandler);
}

export function putApi(url: string, payload: any) {
  return axios
    .put(createBackendUrl(url), payload, { headers: getHeaders(payload) })
    .then(successHandler)
    .catch(errorHandler);
}

export function patchApi(url: string, payload: any) {
  return axios
    .patch(createBackendUrl(url), payload, { headers: getHeaders(payload) })
    .then(successHandler)
    .catch(errorHandler);
}
export function deleteApi(url: string) {
  return axios
    .delete(createBackendUrl(url), { headers: getHeaders() })
    .then(successHandler)
    .catch(errorHandler);
}
