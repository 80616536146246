import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { postApi, getApi, deleteApi, putApi } from '../utils/fetchUtils';
import { Project, CallbackType, showSnackbarType, CallbackModelType, ProjectCategory } from '../utils/types';
import { SEVERITY } from '../utils/enums';
import { AppThunk } from '../store';


const initialState = {
  projects: null as { [id: string]: Project } | null,
  sceneProjects: null as { [id: string]: Project[] } | null,
  projectRequestId: {} as { [id: string]: string },
  projectCategories: [] as ProjectCategory[]
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    updateProjects: (state, action: PayloadAction<Project[]>) => {
      state.projects = {}
      action.payload.forEach(project => {
        if (!state.projects) { state.projects = {}; }
        state.projects[project.id] = { ...(state.projects[project.id] ?? {}), ...project };
      });
    },

    updateSceneProjects: (state, action: PayloadAction<Project[]>) => {
      state.sceneProjects = {};
      action.payload.forEach(project => {
        if (project.mainProjectId) {
          if (!state.sceneProjects) { state.sceneProjects = {}; }

          if (state.sceneProjects[project.mainProjectId]) {
            state.sceneProjects[project.mainProjectId].push(project);
          } else {
            state.sceneProjects[project.mainProjectId] = [project];
          }
        }
      });
    },

    deleteProjectAction: (state, action: PayloadAction<string>) => {
      if (state.projects) {
        const project = state.projects[action.payload];
        if (project && project.mainProjectId && state.sceneProjects && state.sceneProjects[project.mainProjectId]) {
          state.sceneProjects[project.mainProjectId] = state.sceneProjects[project.mainProjectId].filter((prj) => prj.id !== project.id);
        }
        delete state.projects[action.payload];
      }
    },

    updateProjectsById: (state, action: PayloadAction<Project>) => {
      if (!state.projects) { state.projects = {}; }
      state.projects[action.payload.id] = { ...(state.projects[action.payload.id] ?? {}), ...action.payload };

      if (!state.sceneProjects) { state.sceneProjects = {}; }
      if (action.payload.mainProjectId) {
        if (state.sceneProjects[action.payload.mainProjectId]) {
          state.sceneProjects[action.payload.mainProjectId] = state.sceneProjects[action.payload.mainProjectId].filter((proj) => proj.id !== action.payload.id)
          state.sceneProjects[action.payload.mainProjectId].push(action.payload);
        } else {
          state.sceneProjects[action.payload.mainProjectId] = [action.payload];
        }
        state.sceneProjects[action.payload.mainProjectId] = state.sceneProjects[action.payload.mainProjectId].sort((a, b) => a.createdAt > b.createdAt ? 1 : -1);
      }
    },

    updateProjectsData: (state, action: PayloadAction<{ id: string, data: any }>) => {
      if (!state.projects) { state.projects = {}; }
      const project = (state.projects[action.payload.id] ?? { data: {} });
      state.projects[action.payload.id] = { ...project, data: { ...project.data, ...action.payload.data } };
    },

    updateProjectsWithoutData: (state, action: PayloadAction<Project>) => {
      if (!state.projects) { state.projects = {}; }
      const project = (state.projects[action.payload.id] ?? { data: {} });
      state.projects[action.payload.id] = { ...project, ...action.payload, data: { ...action.payload.data, ...project.data } };
    },

    setUpdateRequestId: (state, action: PayloadAction<{ projectId: string, requestId: string }>) => {
      state.projectRequestId[action.payload.projectId] = action.payload.requestId;
    },
    updateProjectCategory: (state, action: PayloadAction<ProjectCategory[]>) => {
      state.projectCategories = action.payload;
    },

  },
});

export default projectSlice.reducer;

export const createProject = (payload: any, showSnackbar?: showSnackbarType, callback?: CallbackModelType<Project>): AppThunk =>
  async dispatch => {
    const project: Project = await postApi("projects/", payload);
    dispatch(projectSlice.actions.updateProjectsById(project));
    showSnackbar?.("Project Created.", SEVERITY.SUCCESS);
    if ("scenes" in payload && showSnackbar) {
      dispatch(getAllProjects(() => callback?.(project)));
    } else {
      callback?.(project);
    }
  }

export const getAllProjects = (callback?: CallbackType): AppThunk =>
  async dispatch => {
    const projects: Project[] = await getApi("projects/");
    const sortedProjects = projects?.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1);
    dispatch(projectSlice.actions.updateProjects(sortedProjects));
    dispatch(projectSlice.actions.updateSceneProjects(sortedProjects));
    callback?.();
  }

export const getProjectById = (id: string, callback?: CallbackModelType<Project>): AppThunk =>
  async dispatch => {
    const project: Project = await getApi(`project/${id}`);
    dispatch(projectSlice.actions.updateProjectsById(project));
    callback?.(project);
  }

export const updateProjectById = (id: string, data: any, showSnackbar?: showSnackbarType, waitForMore: boolean = true): AppThunk =>
  async (dispatch, getState) => {
    // dispatch(projectSlice.actions.updateProjectsData({ id, data }));
    const project: Project = await putApi(`projects/${id}/`, data);
    dispatch(projectSlice.actions.updateProjectsWithoutData(project));
    showSnackbar?.("Project updated.", SEVERITY.SUCCESS);
  }

export const deleteProjectById = (id: string, showSnackbar?: showSnackbarType, callback?: CallbackType): AppThunk =>
  async dispatch => {
    await deleteApi(`projects/${id}/`);
    dispatch(projectSlice.actions.deleteProjectAction(id));
    showSnackbar?.("Project Deleted.", SEVERITY.SUCCESS);
    if (showSnackbar) {
      dispatch(getAllProjects(callback));
      // dispatch(getAllOutputs());
    } else {
      callback?.();
    }
  }
export const getProjectCategories = (callback?: CallbackType): AppThunk =>
  async dispatch => {
    const projectCategories: ProjectCategory[] = await getApi("product_categories/");
    dispatch(projectSlice.actions.updateProjectCategory(projectCategories));
    callback?.();
  }