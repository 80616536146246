import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Chat } from "utils/types";

const initialState = {
  chats: [] as Chat[],
  chatHistory: [] as Chat[],
  isHistoryUsed: false,
};
export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    updateChats: (state, action: PayloadAction<Chat>) => {
      state.chats = state.chats.concat(action.payload);
      state.isHistoryUsed = false;
    },
    updateChatHistory: (state, action: PayloadAction<Chat[]>) => {
      state.chatHistory = action.payload;
      state.isHistoryUsed = false;
    },
    resetChats: (state) => {
      state.chats = [];
      state.isHistoryUsed = false;
    },
    removeLastElement: (state) => {
      const copyChats = [...state.chats];
      copyChats.pop();
      state.chats = copyChats;
      state.isHistoryUsed = false;
    },
    setChats: (state, action: PayloadAction<Chat[]>) => {
      state.chats = action.payload;
      state.isHistoryUsed = true;
    },
  },
});

export default chatSlice.reducer;
