import React, { useState } from "react";
import {
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  capitalize,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { KeyboardArrowUp } from "@mui/icons-material";
import { GLOBAL_ILLUMINATIONS, GRAPHICS_SETTINGS } from "utils/enums";
import { TypographyBodyLarge } from "components/StyledComponets/Typography";

const SelectGlobalIll = ({
  value,
  onChange,
  name,
}: {
  name: string;
  value: string;
  onChange: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Grid display={"flex"} flexDirection={"column"} width={"100%"} gap={"12px"}>
      <TypographyBodyLarge sx={{ fontSize: "14px !important" }}>
        Global Illuminations *
      </TypographyBodyLarge>
      <Select
        displayEmpty
        placeholder="Type"
        name={name}
        open={open}
        value={value}
        onChange={onChange}
        onClose={handleClose}
        onOpen={handleOpen}
        label="Type"
        IconComponent={() =>
          open ? (
            <KeyboardArrowUp
              onClick={handleClose}
              sx={{
                color: "#7D899C",
                fontSize: "24px",
                marginRight: "16px",
                cursor: "pointer",
              }}
            />
          ) : (
            <KeyboardArrowDownIcon
              onClick={handleOpen}
              sx={{
                color: "#7D899C",
                fontSize: "24px",
                marginRight: "16px",
                cursor: "pointer",
              }}
            />
          )
        }
        inputProps={{
          "aria-label": "Without label",
        }}
        sx={{
          width: "100%",
          height: "44px",
          padding: "0px !important",
          borderRadius: "8px",
          backgroundColor: "#1A202B",
          ".MuiOutlinedInput-notchedOutline": {
            border: "0px",
            borderWidth: "0px",
          },
          ".MuiInputBase-input": {
            padding: "0px 12px !important",
            fontFamily: "Inter !important",
            fontWeight: "400 !important",
            fontSize: "14px !important",
            lineHeight: "22px !important",
            color: "#AEB6C4",
          },
          ".MuiOutlinedInput-root:focus": { border: 0 },
          "&:hover": {
            backgroundColor: "#394455 !important",
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              bgcolor: "#253042",
              "& .MuiMenuItem-root": {
                padding: 2,
              },
            },
          },
        }}
      >
        {GLOBAL_ILLUMINATIONS?.map((item: any, index: any) => (
          <MenuItem
            key={index}
            sx={{
              color: "#FFFFFF !important",
              fontSize: "14px !important",
              fontWeight: 400,
              lineHeight: "22px !important",
              padding: "8px 16px !important",
              "&:hover": {
                backgroundColor: "#394455 !important",
                border: "1px solid #0869FB",
              },
            }}
            value={item}
          >
            {capitalize(item)}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};

export default React.memo(SelectGlobalIll);
