import React from "react";
import { CircularProgress } from "@mui/material";

type LoaderProps = {
  type?: string,
}


export function Loader({ type = "" }: LoaderProps) {
  return <CircularProgress size="1.5rem" sx={{ color: type === "primary" ? "white" : "primary", minHeight: "24px", minWidth: "24px" }} />;
}
