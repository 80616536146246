
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { getApi, postApi } from '../utils/fetchUtils';
import { CallbackType, SceneAsset } from '../utils/types';
import { AppThunk } from '../store';

const initialState = {
    backgroundDisplayAssets: [] as SceneAsset[],

}

const assetSlice = createSlice({
    name: "asset",
    initialState,
    reducers: {

        updateBackgroundDisplayAssets: (state, action: PayloadAction<SceneAsset[]>) => {
            state.backgroundDisplayAssets = action.payload;
        },
        // updateBackGroundAssetsEach: (state, action: PayloadAction<SceneAsset>) => {
        //     state.backgroundDisplayAssets = action.payload;
        // },

    }
});

export default assetSlice.reducer;
export const getAllAssets = (callback?: CallbackType): AppThunk =>
    async dispatch => {
        const allSceneAsset: SceneAsset[] = await getApi("maps/");
        dispatch(assetSlice.actions.updateBackgroundDisplayAssets(allSceneAsset));

        callback?.();
    }
export const updateBackgroundDisplay = (payload: any, callback?: CallbackType): AppThunk =>
    async dispatch => {
        const asset: SceneAsset = await postApi("maps/", payload);
        console.log("asset", asset)
        // dispatch(assetSlice.actions.updateBackgroundDisplayAssets(asset));

        callback?.();
    }