import { createSlice } from "@reduxjs/toolkit";
import { Invite, User } from "../utils/types";

const initialState = {
	brandUsers: null as { [id: string]: User } | null,
	invitedUsers: [] as Invite[]
}

export const brandSlice = createSlice({
	name: "brand",
	initialState,
	reducers: {
		updateBrandUsers: (state, action) => {
			state.brandUsers = {};
			action.payload.forEach((user: any) => {
				if (!state.brandUsers) { state.brandUsers = {}; }
				state.brandUsers[user.id] = user;
			})
		},
		updateInvitedUsers: (state, action) => {
			state.invitedUsers = action.payload;
		},
	},
})

export const {
	updateBrandUsers,
	updateInvitedUsers,
} = brandSlice.actions

export default brandSlice.reducer;
