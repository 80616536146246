import { Box, Grid, InputLabel, Typography } from "@mui/material";
import { TextFieldStyled } from "./Input";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as Upload } from "../../assets/icons/upload_file.svg";
import { TypographyBodyDefault } from "./Typography";
import MultipleSelectCheckmarks from "./SelectField";
import Tooltip from "./Tooltip";
import CreateIcon from '@mui/icons-material/Create';
import { file } from "jszip";

const imageExtension = [".jpg", ".jpeg", ".png", ".webp", ".gif"];

function FormFieldsWithType({ property, value, setValue }: any) {


  const [showUI, setShowUI] = useState(false);
  const isGif = imageExtension.some(item => value && typeof value === "string" && value.includes(item));
  const isThumbnail = property && property?.fieldType === "fileUpload" && property?.title === "Thumbnail";
  const url = isThumbnail && value && typeof value !== "string" && value instanceof File && URL.createObjectURL(value);
  console.log("value", value, property)

  useEffect(() => {
    setTimeout(() => {
      setShowUI(true);
    }, 100);
  }, []);
  const hiddenFileInput: any = useRef(null);
  return (
    <>
      {!showUI ? (
        <></>
      ) : (
        <Box paddingBottom={"0.5rem"} color={"white"} width={"100%"}>
          {property?.fieldType === "input" ? (
            <>
              <InputLabel sx={{ color: "white", display: "flex" }}>
                {property?.title + " "}
                {property?.required && (
                  <Typography color={"red"}> *</Typography>
                )}
              </InputLabel>
              <TextFieldStyled
                fullWidth
                placeholder={property?.title + (property?.required ? "*" : "")}
                value={value}
                onChange={(e: any) => setValue(e.target.value)}
              />
            </>
          ) : property?.fieldType === "fileUpload" ? (
            <>
              <Box display="flex">
                {property?.title}{" "}
                {property?.required && (
                  <Typography color={"red"}> *</Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "1.5rem 0rem",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.625rem",
                  flex: "1 0 0",
                  borderRadius: "1rem",
                  border: `1px dashed var(--color-palette-gray-700, #394455)`,
                }}
                onClick={() => {
                  if (!value || isThumbnail) {
                    hiddenFileInput?.current?.click();
                  }
                }}
              >
                {value && !isThumbnail ? (
                  <TypographyBodyDefault fontWeight={600}>
                    {value?.name ?? "Uploaded"}
                  </TypographyBodyDefault>
                ) : (
                  <>
                    <Tooltip
                      title={
                        property?.title === "File"
                          ? "Upload a .json file"
                          : property?.title === "StaticMesh (.glb)"
                            ? "Upload a .glb file"
                            : "Upload a .jpg, .jpeg, .png, .gif file"
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          cursor: "pointer",
                        }}
                      >
                        {
                          value && isThumbnail ?
                            <Grid
                              sx={{
                                position: 'relative'
                              }}
                            >
                              <CreateIcon sx={{ position: 'absolute', zIndex: 2, top: '40px', left: '40px' }} />
                              <img alt="preview" src={isGif ? value : url} style={{ height: '100px', width: '100px' }} />
                            </Grid>
                            : <>
                              <Upload />
                              <TypographyBodyDefault
                                fontWeight={600}
                              >{`Upload ${property?.title}`}</TypographyBodyDefault>
                            </>
                        }

                      </div>
                    </Tooltip>
                  </>
                )}
              </Box>
              <input
                ref={hiddenFileInput}
                type="file"
                style={{ display: "none" }}
                onChange={(e: any) => {
                  if (!e.target.files || e.target.files.length === 0) {
                    return
                  }
                  setValue(e.target.files[0])

                }}
              />
            </>
          ) : property?.fieldType === "multiSelect" ||
            property?.fieldType === "singleSelect" ? (
            <MultipleSelectCheckmarks
              values={value}
              setValues={setValue}
              title={
                <Box display="flex">
                  {property?.title}{" "}
                  {property?.required && (
                    <Typography color={"red"}> *</Typography>
                  )}
                </Box>
              }
              multiple={property?.fieldType === "multiSelect"}
              options={property?.options ?? []}
            />
          ) : (
            <>{property?.title}</>
          )}
        </Box>
      )}
    </>
  );
}

export default FormFieldsWithType;
