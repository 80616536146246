import PixisLogo from "../../assets/PixisLogo.png";
import {
  HomeIcon,
  SeperatorDashedIcon,
  SeperatorIcon,
} from "../Icons";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../hooks/store";
import { ROUTE_PATH_MAP } from "../../utils/enums";
import "./header.css";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Fade,
  Grid,
  Icon,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  TypographyBodyDefault,
  TypographyBodySmallNormal,
  TypographyCTA1,
} from "../StyledComponets/Typography";
import { enumToName } from "../../utils/utils";
import { navbarList } from "../Navbar";
import { ActionButton } from "../../utils/types";
import { Logout } from "@mui/icons-material";
import { PrimaryButton } from "../StyledComponets/Button";

import Tooltip from "../StyledComponets/Tooltip";


const HeaderBreadcrumbs = () => {
  const navigate = useNavigate();

  const { projectId } = useParams();
  const project = useAppSelector(store => store.project.projects && projectId ? store.project.projects[projectId] : undefined);


  const [active, setActive] = useState("");
  const path = active.split("/");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setActive(window.location.pathname);
    document.title = 'Editor Header';
  }, []);

  const handleLogoClick = () => {
    console.log(JSON.stringify({ "unrealEvent": "HOMESCREEN" }))
    // navigate(ROUTE_PATH_MAP.HOME);

  }
  if (active === ROUTE_PATH_MAP.HOME) return <></>;
  return (
    <Breadcrumbs
      separator={
        <Icon
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& svg": { width: "20px", height: "20px" },
            cursor: "default",
          }}
        >
          {SeperatorIcon}
        </Icon>
      }
    >
      {path.length > 1 &&
        path[1] !== "home" &&
        path.slice(0, -1).map((val, index) => {

          const icon = navbarList.find(
            (value) => value.label === enumToName(val)
          );

          if (index === 0) {
            return (
              <Tooltip key={index} title="Home">
                <Icon
                  onClick={handleLogoClick}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& svg": { width: "20px", height: "20px" },
                    ":hover": {
                      "& path": {
                        fill: "var(--color-palette-gray-50, #F6F7F8)",
                      },
                    },
                  }}
                >
                  {HomeIcon}
                </Icon>
              </Tooltip>
            );
          }
          if (
            (index === 2 && projectId && project && path[1] === "editor") ||
            (index === 3 && projectId && project && path[1] === "create")
          ) {

            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  gap: "4px",
                  alignItems: "center",
                  ":hover": {
                    "& svg": {
                      opacity: 1,
                    },
                    "& p": {
                      color: "var(--color-palette-gray-50, #F6F7F8)",
                    },
                    "& path": {
                      fill: "var(--color-palette-gray-50, #F6F7F8)",
                    },
                  },
                }}
              >
                <TypographyBodyDefault
                  key={index}
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  {project.name}
                </TypographyBodyDefault>
              </Box>
            );
          }
          return icon ? (
            <Box
              key={index}
              onClick={() => navigate(icon.value)}
              sx={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
                ":hover": {
                  "& p": {
                    color: "var(--color-palette-gray-50, #F6F7F8)",
                  },
                  "& path": {
                    fill: "var(--color-palette-gray-50, #F6F7F8)",
                  },
                },
              }}
            >
              <Icon
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "& svg": { width: "16px", height: "16px" },
                }}
              >
                {icon.icon}
              </Icon>
              <TypographyBodyDefault sx={{ fontWeight: 600 }}>
                {enumToName(val)}
              </TypographyBodyDefault>
            </Box>
          ) : (
            <Box
              key={index}
              sx={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
                ":hover": {
                  "& p": {
                    color: "var(--color-palette-gray-50, #F6F7F8)",
                  },
                },
              }}
            >
              <TypographyBodyDefault sx={{ fontWeight: 600 }}>
                {enumToName(val)}
              </TypographyBodyDefault>
            </Box>
          );
        })}
    </Breadcrumbs>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StepBreadcrumbs = () => {
  const navigate = useNavigate();


  const { projectId } = useParams();
  const project = useAppSelector(store => store.project.projects && projectId ? store.project.projects[projectId] : undefined);

  let steps: { label: string; route: string | null }[] = [];
  let currentStep = 1;

  if (!project) {
    currentStep = 2;
    steps.push({ label: "Choose Ads Images", route: null });
    steps.push({ label: "Edit Ads", route: null });
    steps.push({ label: "Export", route: null });
  }



  return (
    <Breadcrumbs
      separator={
        <Icon
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& svg": { width: "20px", height: "20px" },
            cursor: "default",
          }}
        >
          {SeperatorDashedIcon}
        </Icon>
      }
    >
      {steps.map((val, index) => {
        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => val.route && navigate(val.route)}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: currentStep === index + 1 ? "#0869FB" : "#394455",
                color: currentStep === index + 1 ? "#fff" : "#AEB6C4",
                width: "16px",
                height: "16px",
                borderRadius: "100px",
                fontFamily: "Inter",
                fontSize: "10px",
                fontWeight: 600,
                letterSpacing: "-0.1px",
              }}
            >
              {index + 1}
            </span>
            <TypographyBodySmallNormal
              sx={{
                fontWeight: 500,
                color: currentStep === index + 1 ? "#AEB6C4" : "#7D899C",
              }}
            >
              {enumToName(val.label)}
            </TypographyBodySmallNormal>
          </Box>
        );
      })}
    </Breadcrumbs>
  );
};

type HeaderProps = {
  showButtons?: boolean;
  buttons?: ActionButton[];
  showSteps?: boolean;
  steps?: string[];
  currentStep?: number;
  transparentBg?: boolean;
};

function Header({
  showButtons,
  buttons,
  showSteps,
  transparentBg,
}: HeaderProps) {

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const [isEventDisable, setEventDisable] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(undefined);
  };
  const handleLogout = () => {
    handleClose();
  };
  
  useEffect(() => {
    window.handleAllEventDisable = handleAllEventDisable;
    return () => {
      delete window.handleAllEventDisable;
    }
  }, [])

  const handleAllEventDisable = (isDisable: boolean) => {
    setEventDisable(isDisable)
  }

  function handleClickRenderButton(): void {
    const payload = {
      unrealEvent: "RENDER"
    }
    console.log(JSON.stringify(payload));
  }
  const handleLogoClick = () => {
    console.log(JSON.stringify({ "unrealEvent": "HOMESCREEN" }))
    // navigate(ROUTE_PATH_MAP.HOME);

  }
  return (
    <Grid
      sx={{
        minHeight: "72px",
        height: "72px",
        background: "#1A202B",
        zIndex: 4,
      }}
    >
      {
        isEventDisable ? <Grid
          sx={{
            height: '72px',
            width: '100%',
            display: "flex",
            flex: 1,
            zIndex: 5,
            backgroundColor: 'rgba(255, 255, 255, .4)',
            position: 'absolute'
          }}
        >
        </Grid> : <></>
      }

      <Grid
        sx={{
          position: "fixed",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          height: "72px",
          zIndex: 4,
          background: transparentBg
            ? "#1A202B"
            : "var(--color-palette-gray-800, #253042)",
          boxShadow: transparentBg
            ? "0"
            : "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          padding: "16px 28px 16px 24px",
          borderRadius: "4px",
        }}
      >

        <Menu
          id="fade-menu"
          sx={{
            ".MuiPaper-root": {
              bgcolor: "#253042",
              color: "#fff",
            },
            ".MuiMenu-list": {
              bgcolor: "var(--color-palette-gray-800, #253042)",
              color: "#fff",
              svg: {
                fill: "white",
              },
            },
            li: {
              bgcolor: "var(--color-palette-gray-800, #253042)",
              ":hover": {
                bgcolor: "var(--color-palette-gray-700, #394455)",
              },
            },
          }}
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>

        <Grid display={"flex"} gap={7} alignItems={"center"}>
          <img
            alt="home-icon"
            onClick={handleLogoClick}
            src={PixisLogo}
            style={{ width: "40px", height: "40px", cursor: "pointer" }}
          />
          <Grid display={"flex"} gap={4} alignItems={"center"}>
            <HeaderBreadcrumbs />
            {/* {showSteps && (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ background: "#394455" }}
                />
                <StepBreadcrumbs />
              </>
            )} */}
          </Grid>
        </Grid>

        <Grid
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={4}
        >
          {showButtons &&
            buttons &&
            buttons?.map((btn, idx) => (
              <PrimaryButton
                key={idx}
                onClick={btn.onClick}
                sx={{ padding: "8px 32px" }}
              >
                <TypographyCTA1>{btn.name}</TypographyCTA1>
                <Icon
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "24px",
                    height: "24px",
                  }}
                >
                  {btn.icon}
                </Icon>
              </PrimaryButton>
            ))}
          {/* <Icon
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "40px",
              height: "40px",
              padding: "8px",
              borderRadius: "100px",
              background: "var(--color-palette-gray-800, #253042)",
              cursor: "pointer",
            }}
          >
            {SearchIcon}
          </Icon>
          <Icon
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "40px",
              height: "40px",
              borderRadius: "100px",
              background: "var(--color-palette-gray-900, #1A202B)",
              cursor: "pointer",
            }}
          >
            {NotificationIcon}
          </Icon> */}
          {showSteps &&
            <PrimaryButton
              sx={{
                width: "96px", display: 'flex',
                justifyContent: 'center', alignItems: 'center'
              }}
              onClick={() => handleClickRenderButton()}
            >
              <TypographyCTA1>Render</TypographyCTA1>
            </PrimaryButton>
          }
          <Grid
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={3}
          >


            {/* {user?.picture ? (
              <img
                src={user?.picture}
                style={{
                  width: "40px",
                  height: "40px",
                  fontSize: "18px",
                  borderRadius: "100px",
                  cursor: "pointer",
                }}
                onClick={handleClick}
              />
            ) : ( */}
            <Avatar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "40px",
                height: "40px",
                padding: "8px",
                fontSize: "18px",
                borderRadius: "100px",
                background: "var(--color-palette-gray-800, #253042)",
                cursor: "pointer",
              }}
            // onClick={handleClick}
            />
            {/* )} */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Header;
