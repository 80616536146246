import React, { useState } from "react";
import { Checkbox, InputLabel, MenuItem, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { KeyboardArrowUp } from "@mui/icons-material";

type DialogTextFieldProps = {
  title: any;
  options: any[];
  values: any;
  setValues: any;
  className?: string;
  multiple?: boolean;
};

const DialogSelectField = ({
  title,
  options,
  className,
  values = [],
  setValues,
  multiple = false,
}: DialogTextFieldProps) => {
  const [open, setOpen] = useState(false);
  const [openAddFolder, setOpenAddFolder] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const onToggleAddFolder = () => setOpenAddFolder(!openAddFolder);
  return (
    <>
      <InputLabel id="demo-multiple-checkbox-label" sx={{ color: "white" }}>
        {title}
      </InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        displayEmpty
        placeholder=""
        value={values}
        open={open}
        className={className}
        onChange={(e: any) => {
          setValues(e.target.value);
        }}
        onClose={handleClose}
        onOpen={handleOpen}
        IconComponent={() =>
          open ? (
            <KeyboardArrowUp
              onClick={handleClose}
              sx={{
                color: "#7D899C",
                fontSize: "24px",
                marginRight: "16px",
                cursor: "pointer",
              }}
            />
          ) : (
            <KeyboardArrowDownIcon
              onClick={handleOpen}
              sx={{
                color: "#7D899C",
                fontSize: "24px",
                marginRight: "16px",
                cursor: "pointer",
              }}
            />
          )
        }
        inputProps={{
          "aria-label": "Without label",
        }}
        sx={{
          width: "100%",
          height: "44px",
          padding: "px !important",
          borderRadius: "8px",
          backgroundColor: "#1A202B",
          ".MuiOutlinedInput-notchedOutline": {
            border: "0px",
            borderWidth: "0px",
          },
          ".MuiInputBase-input": {
            padding: "0px 12px !important",
            fontFamily: "Inter !important",
            fontWeight: "400 !important",
            fontSize: "14px !important",
            lineHeight: "22px !important",
            color: "#AEB6C4",
          },
          ".MuiOutlinedInput-root:focus": { border: 0 },
          "&:hover": {
            backgroundColor: "#394455 !important",
          },
        }}
        multiple={multiple}
        MenuProps={{
          PaperProps: {
            sx: {
              bgcolor: "#253042",
              "& .MuiMenuItem-root": {
                padding: 0,
              },
            },
          },
        }}
        renderValue={(items: any) => {
          return (
            <>
              {Array.isArray(items) && items?.length > 0 && multiple
                ? items.map((selectedVal: any) => selectedVal.name)?.join(",")
                : items?.name ?? <>Select</>}
            </>
          );
        }}
      >
        {options.map((item, index) => {
          return (
            <MenuItem
              key={index}
              sx={{
                paddingBottom: "0px !important",
                color: "#FFFFFF !important",
                fontSize: "14px !important",
                fontWeight: 400,
                lineHeight: "22px !important",
                padding: "8px 16px !important",
                "&:hover": {
                  backgroundColor: "#394455 !important",
                },
              }}
              value={item}
            >
              {multiple ? (
                <>
                  <Checkbox
                    checked={
                      values?.findIndex((value: any) => value.id === item.id) >=
                      0
                    }
                  />
                </>
              ) : (
                <></>
              )}
              <>{item.name}</>
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

export default React.memo(DialogSelectField);
