import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./CreateVideoModal.module.css";
import { TextFieldStyled } from "../../../../components/StyledComponets/Input";
import {
  TypographyBodyLarge,
  TypographyCTA1,
  TypographyCTA2,
} from "../../../../components/StyledComponets/Typography";
import { useAppSelector } from "../../../../hooks/store";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../components/StyledComponets/Button";
import ClearIcon from "@mui/icons-material/Clear";
// import DoneIcon from "@mui/icons-material/Done";
import { CREATE_VIDEO_PROJECT_STEP } from "utils/enums";
import ProjectStepper from "../ProjectStepper";
import SearchIcon from "@mui/icons-material/Search";
import { SceneAsset } from "utils/types";
import DehazeIcon from "@mui/icons-material/Dehaze";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Loader } from "components/Loader";
import Tooltip from "components/StyledComponets/Tooltip";

type SelectSceneStepProps = {
  onClose: React.MouseEventHandler<HTMLDivElement>;
  onBack: React.MouseEventHandler<HTMLButtonElement>;
  onNextCameraAndAnimation: React.MouseEventHandler<HTMLButtonElement>;
  selectScene: SceneAsset[] | [];
  setSelectedScene: Function;
  projectName: string;
  isLoading: boolean;
};

const moveInArr = (from: number, to: number, arr: any[]) => {
  arr.splice(to, 0, arr.splice(from, 1)[0]);
  return [...arr];
};

const SelectSceneStep = ({
  onClose,
  projectName,
  onBack,
  onNextCameraAndAnimation,
  isLoading,
  selectScene,
  setSelectedScene,
}: SelectSceneStepProps) => {
  const [search, setSearch] = useState<string>("");
  const [debounceSearch, setDebounceSearch] = useState("");
  const [keyUpTimeout, setKeyUpTimeout] = useState<any>(null);
  const allScene = useAppSelector(
    (store) => store.assets.backgroundDisplayAssets
  );

  const onSearch = (e: any) => {
    const { value } = e.target || {};
    setSearch(value);
    setKeyUpTimeout(clearTimeout(keyUpTimeout));
    setKeyUpTimeout(
      setTimeout(() => {
        setDebounceSearch(value);
      }, 1000)
    );
  };

  const filterScene = (val: string) => {
    return val
      ? allScene?.filter((item) =>
          item?.name?.toLocaleLowerCase()?.startsWith(val.toLocaleLowerCase())
        )
      : allScene;
  };

  const onSelectItem = (item: SceneAsset) => {
    setSelectedScene([...selectScene, item]);
  };

  const onRemoveScene = (index: any) => {
    const temp: SceneAsset[] | { id: "" }[] = [...selectScene];
    const filterData: SceneAsset[] = temp.filter(
      (scene: SceneAsset, tempIndex: any) => tempIndex !== index
    );
    setSelectedScene(filterData);
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    const updatedLayers = moveInArr(sourceIndex, destinationIndex, selectScene);
    setSelectedScene(updatedLayers);
  };

  return (
    <>
      <DialogTitle className={styles.titleDiv}>
        <Grid
          display={"flex"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
          gap={3}
          width={"100%"}
        >
          <TypographyBodyLarge
            sx={{
              color: "var(--color-palette-white, #FFF)",
              alignSelf: "center",
            }}
          >
            {projectName}
          </TypographyBodyLarge>

          <div onClick={onClose} className={styles.iconDiv}>
            <ClearIcon sx={{ color: "white" }} />
          </div>
        </Grid>
        <ProjectStepper currentStep={CREATE_VIDEO_PROJECT_STEP.CHOOSE_SCENE} />
      </DialogTitle>
      <DialogContent sx={{ overflow: "unset", height: "calc(100% - 200px)" }}>
        <Grid
          container
          padding={"1rem"}
          sx={{
            borderRadius: "1rem",
            border: "1px solid #394455",
            height: "100%",
          }}
        >
          <Grid
            display={"flex"}
            flexDirection={"column"}
            xs={8}
            paddingRight={"0.5rem"}
            height={"100%"}
            sx={{
              overflow: "auto",
            }}
          >
            <div className={styles.contentDiv1}>
              <div className={styles.fieldDiv}>
                <TextFieldStyled
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      width: "100%",
                      paddingLeft: "12px !important",
                    },
                    "& .MuiInputBase-root .MuiInputBase-input": {
                      padding: "10px 12px 10px 0px",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#7D899C" }} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Scenes and add to the video"
                  value={search}
                  onChange={onSearch}
                />
              </div>
              <Grid
                display={"flex"}
                flexDirection={"row"}
                flexWrap={"wrap"}
                gap={1.5}
              >
                {filterScene(debounceSearch).map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        onSelectItem(item);
                      }}
                      className={styles.imagesDiv}
                    >
                      {/* <img title={item.name} src={item.thumbnail} alt={item.name} className={styles.img} /> */}
                      <video
                        title={item.name}
                        src={item.thumbnail}
                        className={styles.img}
                        autoPlay
                        loop
                        muted
                        playsInline
                      />
                    </div>
                  );
                })}
              </Grid>
            </div>
          </Grid>
          <Grid
            display={"flex"}
            flexDirection={"column"}
            xs={4}
            paddingLeft={"0.5rem"}
            gap={"0.5rem"}
            height={"100%"}
            sx={{
              overflow: "auto",
            }}
          >
            <TypographyCTA1
              sx={{ color: "#AEB6C4", textAlign: "left", margin: "12px 0px" }}
            >
              Scenes in order
            </TypographyCTA1>
            <div className={styles.contentDiv2}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{
                        width: "100%",
                        gap: "1rem",
                        flexDirection: "column",
                        display: "flex",
                      }}
                      className="droppable"
                    >
                      {selectScene.map((item, index) => (
                        <Draggable
                          key={`${item.id}-${index}`}
                          draggableId={`${item.id}-${index}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={styles.verticalItem}
                              key={index}
                            >
                              <div className={styles.verticalSubItem}>
                                <video
                                  title={item.name}
                                  src={item.thumbnail}
                                  className={styles.smallImg}
                                  autoPlay
                                  loop
                                  muted
                                  playsInline
                                />
                                <TypographyCTA2 sx={{ color: "#AEB6C4" }}>
                                  {item.name}
                                </TypographyCTA2>
                              </div>
                              <div className={styles.verticalSubItem}>
                                <Tooltip title="Close">
                                  <div
                                    onClick={() => {
                                      onRemoveScene(index);
                                    }}
                                  >
                                    <ClearIcon
                                      sx={{
                                        color: "#7D899C",
                                        fontSize: "22px !important",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                                <Tooltip title="Move">
                                  <div>
                                    <DehazeIcon
                                      sx={{
                                        color: "#7D899C",
                                        fontSize: "22px !important",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={styles.lastDiv}>
          <SecondaryButton onClick={onBack} sx={{ p: "16px 24px" }}>
            <TypographyCTA1>Back</TypographyCTA1>
          </SecondaryButton>
          <PrimaryButton
            onClick={isLoading ? () => null : onNextCameraAndAnimation}
            sx={{ p: "16px 24px" }}
          >
            <TypographyCTA1>
              {isLoading ? <Loader type="primary" /> : "Create Project"}
            </TypographyCTA1>
          </PrimaryButton>
        </div>
      </DialogActions>
    </>
  );
};

export default React.memo(SelectSceneStep);
