import React, { Suspense, useEffect, useRef } from 'react';

import './App.css';
import LoadingPage from './containers/LoadingPage';
import { useRoutes } from 'react-router-dom';
import routes from './routes/routes';
import { useAppDispatch } from './hooks/store';
import { getAllOutputs } from './store/output';
import { getAllProjects, getProjectCategories } from './store/project';
import { getAllFolders } from './store/folder';
import { getAllAssets } from './store/assets';
import { getAllProduct } from 'store/product';

function App() {
  const routesResult = useRoutes(routes);
  const dispatch = useAppDispatch();
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      dispatch(getAllAssets())
      dispatch(getProjectCategories())
      dispatch(getAllOutputs());
      dispatch(getAllProduct());
      dispatch(getAllProjects());
      dispatch(getAllFolders());
      firstRender.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="App">
      <Suspense fallback={LoadingPage}>{routesResult}</Suspense>

    </div>
  );
}

export default App;
