import { Grid } from '@mui/material';
import { TypographyBodyMedium } from 'components/StyledComponets/Typography';
import React from 'react'
import { CREATE_VIDEO_PROJECT_STEP } from 'utils/enums';


type ProjectStepperProps = {
    currentStep: CREATE_VIDEO_PROJECT_STEP
};

const INTERNAL_STEPS: { index: number, label: string, step: CREATE_VIDEO_PROJECT_STEP }[] = [
    { index: 1, label: "Choose Scenes", step: CREATE_VIDEO_PROJECT_STEP.CHOOSE_SCENE },
    { index: 2, label: "Choose Camera and Animation", step: CREATE_VIDEO_PROJECT_STEP.CHOOSE_CAMERA_ANIMATION }
]

const ProjectStepper = ({ currentStep }: ProjectStepperProps) => {

    return (
        <Grid
            display={"flex"}
            width={"100%"}
            gap={1}
            alignItems={"center"}
        >
            {
                INTERNAL_STEPS.map((item, index) => {
                    return (
                        <Grid
                            key={index}
                            display={"flex"}
                            alignItems={"center"}
                            gap={1}
                        >
                            <div style={{
                                height: '16px',
                                width: '16px',
                                borderRadius: '50%',
                                backgroundColor: currentStep === item.step ? "#0869FB" : "#5C6674",
                                color: '#fff',
                                fontSize: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }} >{item.index}</div>
                            <TypographyBodyMedium sx={{ color: currentStep !== item.step ? "#7D899C" : "#AEB6C4" }}  >{item.label}</TypographyBodyMedium>
                            {
                                index < INTERNAL_STEPS.length - 1 ? <div style={{
                                    height: '0px',
                                    width: '16px',
                                    border: '1px dashed #5C6674'
                                }} /> : <></>
                            }
                        </Grid>
                    )
                })
            }

        </Grid>
    )

}

export default React.memo(ProjectStepper);
