import React, { ReactNode, createContext, useEffect, useState } from "react";
import { Alert,  Slide, Snackbar, Stack } from "@mui/material";
import { SEVERITY } from "../../utils/enums";
import { showSnackbarType } from "../../utils/types";

const getBackgroundColor = (severity: SEVERITY) => {
    switch (severity) {
        case SEVERITY.ERROR:
            return "rgb(255, 102, 102)";
        case SEVERITY.WARNING:
            return "rgb(255, 178, 102)";
        case SEVERITY.INFO:
            return "rgb(102, 178, 255)";
        default:
            return "rgb(102, 255, 102)";
    }
};
interface SnackbarContextType {
    showSnackbar: showSnackbarType;
}

export const SnackbarContext = createContext<SnackbarContextType>({
    showSnackbar: () => { },
});

const SnackbarProvider = ({ children }: { children: ReactNode }) => {
    const [snackbarQueue, setSnackbarQueue] = useState<{ message: string, severity: SEVERITY, duration: number }[]>([]);
    const handleClose = (snackbarId: number) => {
        setSnackbarQueue(snackbarQueue.filter((_, id) => id !== snackbarId));
    };

    const showSnackbar = (
        message: string,
        severity: SEVERITY = SEVERITY.SUCCESS,
        duration: number = 4000
    ) => {
        setSnackbarQueue(prevState => [...prevState, { message, severity, duration }]);
    };

    useEffect(() => {
        if (snackbarQueue.length > 0) {
            const timer = setTimeout(() => {
                setSnackbarQueue(prevState => prevState.slice(1));
            }, snackbarQueue[0].duration);

            return () => clearTimeout(timer);
        }
    }, [snackbarQueue]);

    const snackbarContext: SnackbarContextType = {
        showSnackbar,
    };

    return (
        <SnackbarContext.Provider value={snackbarContext}>
            {children}
            <Stack spacing={1} sx={{
                position: "fixed",
                left: "50%",
                bottom: "5px",
                transform: "translate(-50%, 0)",
                zIndex: 10000,
            }}>
                {snackbarQueue.slice(0, 4).map((snackbar, id) => <Snackbar
                    key={id}
                    open={true}
                    autoHideDuration={snackbar.duration}
                    sx={{
                        position: "relative",
                    }}
                    transitionDuration={300}
                    TransitionComponent={Slide}
                >
                    <Alert onClose={() => handleClose(id)} severity={snackbar.severity} sx={{ minWidth: "300px", bgcolor: getBackgroundColor(snackbar.severity) }}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>)}
            </Stack>
        </SnackbarContext.Provider>
    );
};

export default SnackbarProvider;
