import React, { useState, useContext } from "react";
import { DialogStyled } from "../../../../components/StyledComponets/Dialog";
import { Folder, ProjectCategory, SceneAsset } from "../../../../utils/types";
import { useAppDispatch, useAppSelector } from "../../../../hooks/store";
import { useLocation, useNavigate } from "react-router-dom";
import { CREATE_VIDEO_PROJECT_STEP, ROUTE_PATH_MAP, SEVERITY } from "../../../../utils/enums";
import NewProjectStep from "./NewProjectStep";
import SelectSceneStep from "./SelectSceneStep";
import { createProject } from "store/project";
import { SnackbarContext } from "components/Snackbar";
import { Project } from '../../../../utils/types';
import SelectCameraAndAnimationStep from "./SelectCameraAndAnimationStep";
import { getPayloadScene } from "utils/utils";
import { faker } from '@faker-js/faker';


type StyledDialogProps = {
    open: boolean;
    onClose: React.MouseEventHandler<HTMLDivElement>;
};
const CreateVideoModal = ({ open, onClose }: StyledDialogProps) => {

    const folders = useAppSelector(store => store.folder.allFolders ?? {});
    const displayFolders: Folder[] = [
        // { id: "drafts", name: "Drafts" } as Folder,
        ...Object.values(folders),
    ];


    const location = useLocation();
    const folderId: string | undefined = location.state?.folderId;

    const adjective = faker.word.adjective();
    const noun = faker.word.noun();
    const number = Math.floor(Math.random() * 100);
    const randomName = `${adjective}-${noun}-${number}`;

    const [isLoading, setLoading] = useState<boolean>(false)
    const [projectName, setProjectName] = useState<string>(randomName);
    const [selectCategory, setSelectCategory] = useState<ProjectCategory | { name: "", id: "" }>({ name: "", id: "" });
    const [selectDuration, setSelectDuration] = useState<{ label: string, value: number | null }>({ label: "5 sec", value: 5000 });
    const [selectRatio, setSelectRatio] = useState<{ label: string, value: number, size: string }>({ label: "", value: 0, size: "" });
    const [selectScene, setSelectedScene] = useState<SceneAsset[]>([]);
    const [folder, setFolder] = useState<string>(folderId || displayFolders[0]?.id);
    const [step, setStep] = useState<CREATE_VIDEO_PROJECT_STEP>(CREATE_VIDEO_PROJECT_STEP.ADD_DETAILS)
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const { showSnackbar } = useContext(SnackbarContext);

    const handleProjectCreate = () => {
        // Check if projectName contains only letters, digits, spaces, hyphens, and underscores
        if (!/^[a-zA-Z\d _-]+$/.test(projectName)) {
            showSnackbar("Invalid Project name.", SEVERITY.WARNING);
            return;
        }
        if (!folder) {
            // eslint-disable-next-line no-throw-literal
            showSnackbar("Select atleast one Folder", SEVERITY.WARNING);
            return
        }
        if (!selectCategory.name) {
            // eslint-disable-next-line no-throw-literal
            showSnackbar("Select atleast one category.", SEVERITY.WARNING);
            return
        }
        if (!selectDuration.value) {
            // eslint-disable-next-line no-throw-literal
            showSnackbar("Select atleast one duration.", SEVERITY.WARNING);
            return
        }
        if (!selectRatio.value) {
            // eslint-disable-next-line no-throw-literal
            showSnackbar("Select atleast one aspect ratio.", SEVERITY.WARNING);
            return
        }
        setStep(CREATE_VIDEO_PROJECT_STEP.CHOOSE_SCENE)

    };
    const onNextCameraAndAnimation = (e: any) => {
        if (!selectScene.length) {
            // eslint-disable-next-line no-throw-literal
            showSnackbar("Select atleast one scene", SEVERITY.WARNING);
            return
        }
        onGotoEditor(e)
    }

    const onGotoEditor = (e: any) => new Promise<void>(resolve => {
        setLoading(true)
        const payloadScene = getPayloadScene(selectScene, selectDuration.value)
        // selectScene.map(({ name, id, thumbnail, tags, scope }, index) => ({
        //     name: name,
        //     url: thumbnail,
        //     duration: selectDuration.value,
        //     tags,
        //     scope,
        //     thumbnail: thumbnail,
        //     index,
        //     map: id
        // }));

        const payload = {
            "name": projectName,
            "description": "",
            "thumbnail": "https://t3.ftcdn.net/jpg/04/60/44/08/360_F_460440876_a8lkfz8UHz64eDPFzmSJJZ55UQBNiC0a.jpg",
            "folder": folder === "drafts" ? " " : folder,
            "tags": [],
            "scope": "Global",
            "scenes": payloadScene,
            "aspect_ratio": selectRatio.value,
            "screen_ratio": selectRatio.label,
            "duration": selectDuration.value,
            "product_category": selectCategory.id,
            "created_by": "1f136e43-aaa6-45e1-877f-861a71e6f22b",
            "last_updated_by": "1f136e43-aaa6-45e1-877f-861a71e6f22b"
        }

        dispatch(createProject(
            payload,
            showSnackbar,
            (project: Project) => {
                resolve();
                setLoading(false);
                onClose(e);
                navigate(`${ROUTE_PATH_MAP.VIDEO_EDITOR}/${project?.id}`);

                //Unreal browser event for create new project
                const un_payload = {
                    "unrealEvent": "CREATE_PROJECT", "data": { "project": project }
                }
                console.log(JSON.stringify(un_payload))
            },
        ));

    });
    const onBack = () => {
        setStep(CREATE_VIDEO_PROJECT_STEP.ADD_DETAILS)
    }
    const returnStepUI = () => {
        if (step === CREATE_VIDEO_PROJECT_STEP.ADD_DETAILS) {
            return (
                <NewProjectStep
                    onClose={onClose}
                    folder={folder}
                    setFolder={setFolder}
                    projectName={projectName}
                    setProjectName={setProjectName}
                    selectCategory={selectCategory}
                    setSelectCategory={setSelectCategory}
                    selectDuration={selectDuration}
                    setSelectDuration={setSelectDuration}
                    selectRatio={selectRatio}
                    setSelectRatio={setSelectRatio}
                    onSelectScene={handleProjectCreate}
                />
            )
        }
        else if (step === CREATE_VIDEO_PROJECT_STEP.CHOOSE_SCENE) {
            return (
                <SelectSceneStep
                    isLoading={isLoading}
                    projectName={projectName}
                    selectScene={selectScene}
                    setSelectedScene={setSelectedScene}
                    onClose={onClose} onBack={onBack} onNextCameraAndAnimation={onNextCameraAndAnimation} />
            )
        } else if (step === CREATE_VIDEO_PROJECT_STEP.CHOOSE_CAMERA_ANIMATION) {
            return (
                <SelectCameraAndAnimationStep
                    projectName={projectName}
                    selectScene={selectScene}
                    onGotoEditor={onGotoEditor}
                    onClose={onClose} onBack={onBack} />
            )
        }
    }

    return (
        <DialogStyled onClose={onClose} open={open}>
            {returnStepUI()}
        </DialogStyled>
    );
};

export default React.memo(CreateVideoModal);
