import { Grid } from '@mui/material';
import React from 'react'
import {  TypographyBodyLarge, TypographyTitleGroup } from '../../../../components/StyledComponets/Typography';
import { RATIO_TYPE } from '../../../../utils/enums';
import CheckIcon from '@mui/icons-material/Check';


const ASPECT_RATIO_OPTIONS_MAP: { [id in RATIO_TYPE]: { label: string, value: number, size: string } } = {
    [RATIO_TYPE["16_9"]]: { label: "16:9", value: 16 / 9, size: '1920x1080' },
    [RATIO_TYPE["9_16"]]: { label: "9:16", value: 9 / 16, size: '1080x1920' },
    [RATIO_TYPE["1_1"]]: { label: "1:1", value: 1 / 1, size: '1080x1080' },
    [RATIO_TYPE["4_5"]]: { label: "4:5", value: 4 / 5, size: '1080x1350' },
};

type SelectCategoryProps = { selectRatio: { label: string, value: number, size: string }, setSelectRatio: Function };

const SelectRatio = ({ selectRatio, setSelectRatio }: SelectCategoryProps) => {

    return (
        <Grid
            display={"flex"}
            width={"100%"}
            gap={1.5}
            flexDirection={"column"}
        >
            <TypographyBodyLarge sx={{ fontSize: '14px !important' }} >Aspect Ratio *</TypographyBodyLarge>
            <div style={{ display: 'flex', alignItems: 'center', gap: "16px" }} >
                {Object.keys(ASPECT_RATIO_OPTIONS_MAP).map((option) => {
                    const option_value = ASPECT_RATIO_OPTIONS_MAP[option as RATIO_TYPE];
                    return <button
                        key={option_value.value}
                        style={{
                            backgroundColor: "#253042",
                            padding: '0.5rem 1rem',
                            borderRadius: '10px',
                            border: selectRatio.value === option_value.value ? '2px solid white' : '2px solid #394455',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                            justifyContent: 'center',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '14px',
                            color: '#FFFFFF',
                            cursor: 'pointer',
                            position: 'relative',
                            flexDirection: 'row'

                        }}
                        onClick={() => setSelectRatio(option_value)}
                    >
                        <div>
                            <TypographyTitleGroup style={{ color: '#7D899C' }}>{option_value.label}</TypographyTitleGroup>

                        </div>
                        <div style={{
                            height: '20px', width: '20px', borderRadius: '50%', backgroundColor: '#1A202B', display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }} >
                            {
                                selectRatio.value === option_value.value ? <CheckIcon sx={{ fontSize: '1rem', color: '#7D899C' }} /> : <></>
                            }
                        </div>

                    </button>
                })}
            </div>

        </Grid>

    )

}

export default React.memo(SelectRatio);
