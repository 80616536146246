import { styled } from '@mui/material/styles';
import { Dialog } from "@mui/material";


export const DialogStyled = styled(Dialog)(() => {
    return {
        '& .MuiDialog-container .MuiPaper-root':{
            backgroundColor: '#253042',
            borderRadius: '1rem',
            height: '85vh',
            minWidth: '60%',
            padding: '2rem',
            gap: '1.5rem',
        },
        '& .MuiDialogContent-root': {
            display: 'flex',
            flexDirection: 'column',
            padding: '0px !important',
            gap: '1.5rem'
        },
    };
  });
  