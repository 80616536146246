import { DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { DialogStyled } from "components/StyledComponets/Dialog";
import { TypographyBodyLarge } from "components/StyledComponets/Typography";
import SelectGlobalIll from "containers/create/component/CreateVideoModal/SelectGlobalIll";
import SelectGraphics from "containers/create/component/CreateVideoModal/SelectGraphics";
import SelectReflections from "containers/create/component/CreateVideoModal/SelectReflections";
import React, { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { AsyncButton } from "components/StyledComponets/Button";
import SelectTextureStreaming from "containers/create/component/CreateVideoModal/SelectTextureStreaming";

type StyledDialogProps = {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLDivElement>;
};
const initialData = {
  graphics: "",
  illumination: "",
  reflections: "",
  textureStreaming: "",
};
const ProductSettingModal = ({ open, onClose }: StyledDialogProps) => {
  const [formData, setFormData] = useState({ ...initialData });

  const onSubmit = () =>
    new Promise<void>((resolve) => {
      if (!formData?.graphics) {
        throw "Invalid Graphics Settings.";
      }
      if (!formData?.illumination) {
        throw "Invalid Global Illuminations.";
      }
      if (!formData?.reflections) {
        throw "Invalid Reflections.";
      }
      if (!formData?.textureStreaming) {
        throw "Invalid Texture Streaming quality";
      }
      console.log(
        JSON.stringify({
          unrealEvent: "PROJECT_SETTINGS",
          data: {
            settings: {
              graphics: formData?.graphics,
              global_illumination: formData?.illumination,
              reflections: formData?.reflections,
              texture_streaming_quality: formData?.textureStreaming,
            },
          },
        })
      );
      resolve();
    });

  const onChange = (e: any) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  return (
    <DialogStyled onClose={onClose} open={open}>
      <DialogTitle sx={{ padding: "0px" }}>
        <Grid
          display={"flex"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
          gap={"4rem"}
          width={"100%"}
        >
          <TypographyBodyLarge
            sx={{
              color: "var(--color-palette-white, #FFF)",
              alignSelf: "center",
            }}
          >
            {"Product Settings"}
          </TypographyBodyLarge>
          <div
            onClick={onClose}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "2rem",
              width: "2rem",
              borderRadius: "50%",
              backgroundColor: "#1A202B",
              cursor: "pointer",
            }}
          >
            <ClearIcon sx={{ color: "white" }} />
          </div>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          display={"flex"}
          flexDirection={"column"}
          gap={"2rem"}
          width={"40%"}
        >
          <SelectGraphics
            name="graphics"
            value={formData.graphics}
            onChange={onChange}
          />
          <SelectGlobalIll
            name="illumination"
            value={formData.illumination}
            onChange={onChange}
          />
          <SelectReflections
            name="reflections"
            value={formData.reflections}
            onChange={onChange}
          />
          <SelectTextureStreaming
            name="textureStreaming"
            value={formData.textureStreaming}
            onChange={onChange}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid width={"100%"} justifyContent={"end"} display={"flex"}>
          <AsyncButton text="save" onClick={onSubmit} />
        </Grid>
      </DialogActions>
    </DialogStyled>
  );
};

export default React.memo(ProductSettingModal);
