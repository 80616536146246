import {
  Action,
  ThunkAction,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
// import projectReducer from './store/project';
import folderReducer from "./store/folder";
import outputReducer from "./store/output";
import projectReducer from "./store/project";
import brandReducer from "./store/brand";
import productReducer from "./store/product";
import assetsReducer from "./store/assets";
import chatReducer from './store/chat';
import assetFormReducer from "./store/assetForm";
import { USER_LOGOUT } from "./utils/enums";

// import { loadState } from './store/browser-storage';

const appReducer = combineReducers({
  project: projectReducer,
  folder: folderReducer,
  output: outputReducer,
  brand: brandReducer,
  assets: assetsReducer,
  product: productReducer,
  assetForm: assetFormReducer,
  chat: chatReducer
});

const rootReducer = (state: any, action: any) => {
  if (action.type === USER_LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  //   preloadedState: loadState(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;
