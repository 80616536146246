import PixisLogo from "../../assets/PixisLogo.png";
import {
  HomeIcon,
  CreateIcon,
  FolderIcon,
  BrandkitIcon,
  AssetsIcon,
  HelpIcon,
  Diamond,
} from "../Icons";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH_MAP } from "../../utils/enums";
import { Grid, Icon, List, ListItem } from "@mui/material";
import { TypographyBodyDefault } from "../StyledComponets/Typography";
import CreateVideoModal from "containers/create/component/CreateVideoModal";
import ProductSettingModal from "components/Modals/ProductSettingModal";


export const navbarList = [
  {
    icon: HomeIcon,
    label: "Home",
    value: ROUTE_PATH_MAP.HOME,
  },
  {
    icon: CreateIcon,
    label: "Create",
    value: ROUTE_PATH_MAP.CREATE,
  },
  {
    icon: FolderIcon,
    label: "Folders",
    value: ROUTE_PATH_MAP.FOLDERS,
  },
  {
    icon: BrandkitIcon,
    label: "Renders",
    value: ROUTE_PATH_MAP.RENDERS,
  },
  {
    icon: AssetsIcon,
    label: "Assets",
    value: ROUTE_PATH_MAP.ASSETS,
  },
  {
    icon: HelpIcon,
    label: "Settings",
    value: ROUTE_PATH_MAP.SETTING,
  },
  {
    icon: Diamond,
    label: "Prompt",
    value: ROUTE_PATH_MAP.PROMPTS,
  },
];

function Navbar() {
  const navigate = useNavigate();
  const active = window.location.pathname
  const [openCreateVideo, setOpenCreateVideo] = useState(false);
  const [openProductSetting, setOpenProductSetting] = useState(false);
  // console.log("Active", active)

  const handleLogoClick = () => {
    navigate(ROUTE_PATH_MAP.HOME);
    console.log(JSON.stringify({ "unrealEvent": "HOMESCREEN" }))
  }

  const onQuickLinkClick = () => {
    setOpenCreateVideo(!openCreateVideo)
  }
  const onToggleProductSetting = () => {
    setOpenProductSetting(!openProductSetting)
  }
  const onOptionClick = (page: any) => {
    // console.log("page", page)
    if (page.value === ROUTE_PATH_MAP.CREATE) {
      onQuickLinkClick();
    } else if (page.value === ROUTE_PATH_MAP.SETTING) {
      onToggleProductSetting();
    } else {
      navigate(page.value);
    }
  }
  return (
    <Grid
      sx={{
        minWidth: "96px",
        width: "96px",
        zIndex: 5,
      }}
    >
      <Grid
        sx={{
          width: "96px",
          padding: "16px",
          height: "calc(100vh)",
          display: "flex",
          position: "fixed",
          top: "0px",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          background: "var(--color-palette-gray-800, #253042)",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          borderRadius: "4px",
          zIndex: 5,
        }}
      >
        <img
          alt="logo"
          onClick={handleLogoClick}
          src={PixisLogo}
          style={{ width: "40px", height: "40px", cursor: "pointer" }}
        />
        <List sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>

          {navbarList.map((page, index) => {
            return (
              <ListItem
                key={index}
                sx={{
                  display: "flex",
                  padding: "8px 4px",
                  flexDirection: "column",
                  gap: "4px",
                  background:
                    active === page.value
                      ? "var(--color-palette-gray-700, #394455)"
                      : "transparent",
                  borderRadius: "8px",
                  ":hover": {
                    background: "var(--color-palette-gray-700, #394455)",
                    borderRadius: "8px",
                    "& path": { fill: "white" },
                  },
                }}
                onClick={() => { onOptionClick(page) }}
              >
                <Icon
                  sx={{
                    width: "24px",
                    height: "24px",
                    fontSize: "24px",
                    "& path": {
                      fill: active === page.value ? "white" : "#7D899C",
                    },
                    "& svg": { width: "24px", height: "24px" },
                  }}
                >
                  {page.icon}
                </Icon>
                <TypographyBodyDefault
                  sx={{
                    fontWeight: active === page.value ? 600 : 400,
                    letterSpacing: "-0.1px",
                    color:
                      active === page.value
                        ? "var(--color-palette-white, #FFF)"
                        : "var(--color-palette-gray-500, #7D899C)",
                  }}
                >
                  {page.label}
                </TypographyBodyDefault>
              </ListItem>
            );
          })}
        </List>
        <img
          alt="logo"
          src={PixisLogo}
          style={{ width: "40px", height: "40px", opacity: 0 }}
        />
      </Grid>
      {
        openCreateVideo && <CreateVideoModal open={openCreateVideo} onClose={onQuickLinkClick} />
      }
      {
        openProductSetting && <ProductSettingModal open={openProductSetting} onClose={onToggleProductSetting} />
      }
    </Grid>

  );
}

export default Navbar;
