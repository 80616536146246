import { FolderFormFieldsType } from "utils/types";

const folderFields: FolderFormFieldsType = {
  apiUrl: "folders/",
  properties: {
    name: {
      type: "string",
      title: "Name",
      fieldType: "input",
      maxLength: 255,
      minLength: 1,
      required: true,
    },
    parent: {
      type: "string",
      title: "Parent Folder",
      minLength: 1,
      maxLength: undefined,
      fieldType: "singleSelect",
      optionsType: "dynamic",
      optionApi: "folders",
      required: false,
      uniqueItems: true,
      items: { type: "stirng" },
    },
    thumbnail: {
      type: "string",
      title: "Thumbnail",
      minLength: 1,
      maxLength: undefined,
      fieldType: "fileUpload",
      required: true,
    },
    users: {
      type: "array",
      uniqueItems: true,
      title: "Users",
      minLength: 1,
      maxLength: undefined,
      fieldType: "multiSelect",
      optionsType: "dynamic",
      optionApi: "users",
      required: false,
      items: { type: "string", format: "uuid" },
    },
    // brands: {
    //   type: "array",
    //   uniqueItems: true,
    //   title: "Brands",
    //   minLength: 1,
    //   maxLength: undefined,
    //   fieldType: "multiSelect",
    //   optionsType: "dynamic",
    //   optionApi: "brands",
    //   required: false,
    //   items: { type: "string", format: "uuid" },
    // },
    tags: {
      type: "array",
      title: "Tags",
      minLength: 1,
      maxLength: undefined,
      fieldType: "multiSelect",
      optionsType: "dynamic",
      optionApi: "tags",
      required: false,
      uniqueItems: true,
      items: { type: "integer" },
    },
    scope: {
      type: "string",
      title: "Scope",
      minLength: 0,
      maxLength: undefined,
      fieldType: "singleSelect",
      optionsType: "static",
      options: [
        { id: "Global", name: "Global" },
        { id: "Brand", name: "Brand" },
        { id: "BrandKit", name: "BrandKit" },
        { id: "Private", name: "Private" },
      ],
      required: true,
      items: { type: "string", format: "uuid" },
    },
    created_by: {
      type: "string",
      title: "Created by",
      minLength: 1,
      maxLength: undefined,
      fieldType: "singleSelect",
      optionsType: "dynamic",
      optionApi: "users",
      required: true,
      last_updated_by: true,
      items: { type: "string", format: "uuid" },
    },
  },
};
export default folderFields;
